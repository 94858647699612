import _isNil from "lodash/isNil";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import React from 'react';
import displayCurrencyByType from '../../../utils/currencyByType';
import { walletType } from '../../../enums/wallets';
import { accountRank, accountRankId } from '../../../enums/account';
import { validDate, displayDate } from '../../../utils/moment';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var leadershipHistoryColumns = function leadershipHistoryColumns(data) {
  var checkForZero = function checkForZero(key) {
    return !!(data !== null && data !== void 0 && data.filter(function (el) {
      return el[key];
    }).length);
  };
  return [{
    name: 'Statement Date',
    render: function render(row) {
      return ___EmotionJSX("h4", null, validDate(row.rankRunTargetDateFrom) && validDate(row.rankRunTargetDateTo) ? "From ".concat(displayDate(row.rankRunTargetDateFrom), " \n           To ").concat(displayDate(row.rankRunTargetDateTo)) : ___EmotionJSX("div", {
        className: "table-data__cell__no-value"
      }));
    },
    width: '150px'
  }, {
    name: 'Left BV',
    render: function render(row) {
      return displayCurrencyByType(row.bvLeftLegForPeriod, walletType.BV);
    }
  }, {
    name: 'Right BV',
    render: function render(row) {
      return displayCurrencyByType(row.bvRightLegForPeriod, walletType.BV);
    }
  }, {
    name: 'Total BV',
    render: function render(row) {
      return displayCurrencyByType(row.bvTotalForPeriod, walletType.BV);
    }
  }, {
    name: 'Your rank',
    render: function render(row) {
      return _isNil(row.leadershipRankId) ? 'None' : accountRankId[row.leadershipRankId];
    },
    width: '90px'
  }, checkForZero(accountRank.Sapphire) && {
    name: 'Sapphire',
    value: accountRank.Sapphire
  }, checkForZero(accountRank.Ruby) && {
    name: 'Ruby',
    value: accountRank.Ruby
  }, checkForZero(accountRank.Emerald) && {
    name: 'Emerald',
    value: accountRank.Emerald
  }, checkForZero(accountRank.Diamond) && {
    name: 'Diamond',
    value: accountRank.Diamond
  }, checkForZero(accountRank.BlueDiamond) && {
    name: 'Blue Diamond',
    value: accountRank.BlueDiamond
  }, checkForZero(accountRank.BlackDiamond) && {
    name: 'Black Diamond',
    value: accountRank.BlackDiamond
  }, checkForZero(accountRank.CrownDiamond) && {
    name: 'Crown Diamond',
    value: accountRank.CrownDiamond
  }].filter(Boolean);
};