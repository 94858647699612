import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ExchangeInput = function ExchangeInput(props) {
  var type = props.type,
    label = props.label,
    appendLabel = props.appendLabel,
    prependLabel = props.prependLabel,
    placeholder = props.placeholder,
    name = props.name,
    handleBlur = props.handleBlur,
    handleChange = props.handleChange,
    value = props.value;
  var inputClasses = ['vk-form-control'];
  var formGroupClasses = ['vk-form-group'];
  return ___EmotionJSX("div", {
    className: formGroupClasses.join(' ')
  }, label && ___EmotionJSX("label", {
    className: "vk-form-label"
  }, label), ___EmotionJSX("div", {
    className: "vk-input-append-wrap"
  }, prependLabel && ___EmotionJSX("div", {
    className: "vk-input-prepend-label"
  }, prependLabel), ___EmotionJSX("input", {
    className: inputClasses.join(' '),
    type: type,
    placeholder: placeholder,
    name: name,
    onBlur: handleBlur,
    onChange: handleChange,
    step: ".1",
    value: value
  }), appendLabel && ___EmotionJSX("div", {
    className: "vk-input-append-label"
  }, appendLabel)));
};
ExchangeInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  appendLabel: PropTypes.any,
  prependLabel: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.any,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
};
export default ExchangeInput;