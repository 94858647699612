export var faqsCardData = [{
  id: 1,
  title: 'Registration',
  icon: 'be-icon icon-note',
  url: '/information-center/faqs/registration'
}, {
  id: 2,
  title: 'Login & Settings',
  icon: 'be-icon icon-computer-settings-interface-symbol-of-a-monitor-screen-with-gears',
  url: '/information-center/faqs/login-settings'
}, {
  id: 3,
  title: 'Bonus opportunities',
  icon: 'be-icon icon-interface',
  url: '/information-center/faqs/bonus-opportunities'
}, {
  id: 4,
  title: 'Promotional Tokens & Splits',
  icon: 'be-icon icon-hand',
  url: '/information-center/faqs/tokens-splits'
}, {
  id: 5,
  title: 'Sponsorship',
  icon: 'be-icon icon-arrow',
  url: '/information-center/faqs/sponsorship'
}, {
  id: 6,
  title: 'Educational Packages & payments',
  icon: 'be-icon icon-commerce-1',
  url: '/information-center/faqs/educational-packages-payments'
}, {
  id: 7,
  title: 'Mining, coins & Coinsafe',
  icon: 'be-icon icon-cart',
  url: '/information-center/faqs/mining-coins-coinsafe'
}, {
  id: 8,
  title: 'Account verification & compliance',
  icon: 'be-icon icon-court',
  url: '/information-center/faqs/account-verification-compliance'
}, {
  id: 9,
  title: 'General account information',
  icon: 'be-icon icon-note',
  url: '/information-center/faqs/general-account-information'
}];
export var registrationData = [{
  id: 1,
  title: 'How to register an account in OneEcosystem?',
  content: 'In order to create an account and register as a member, all you have to do is go to the Home Page of OneEcosystem website <a href=\'https://oneecosystem.eu/\'target="_blank"> https://oneecosystem.eu/en/</a> and then choose Membership ->Join Us. Fill in all fields of the registration form and click on "Get started" option.'
}, {
  id: 2,
  title: 'What happens when I register to OneEcosystem?',
  content: 'The sign up with us is for FREE and after you make a registration you will receive the Rookie package. The Rookie is a free product package that includes information on OneCoin, invitations to webinars and events, the ”The Richest Man in Babylon” e-book by George S. Clason and a newsletter regarding the development of OneEcosystem. This package does not provide a business profile, however it will provide access to the backoffice of your OES account and along with that, an access to the DealShaker platform - the newest virtual advertising platform, enabling Merchants and Re-sellers to globally offer promotions on goods and services.'
}, {
  id: 3,
  title: 'How can I receive a business profile and full access to all available features in the backoffice?',
  content: 'By acquiring any of the educational packages above the Rookie, a business profile is created and full access is given to the backoffice, OneAcademy and all marketing tools.'
}, {
  id: 4,
  title: 'Can I join OneEcosystem Network and be a Member without purchasing an educational package?',
  content: 'It is possible to join OneEcosystem Network without purchasing any of the packages. All you need to do is sign up for a FREE Rookie package. After this if you are a dedicated worker you can earn promotional tokens without spending any money at all. You can earn Business Volume points (BVp) based on your personal sales and qualify for promotional tokens. Take the opportunity to invite people to the OneEcosystem Network and earn 25% BVp on all their personally-purchased Bvps provided with packages. Then you can easily qualify for promotional tokens and get an educational package without buying it.'
}, {
  id: 5,
  title: 'Can you provide an example of how I can receive a free package based on my personal sales?',
  content: 'For example, if you are a FREE member and you have four personally-refered customers who each purchase a Pro Trader Package, here are the figures: 4 x 1100 BV = 4400 PGV; 25% = 1100 BV. You would personally qualify for the level/rank of Pro Trader and receive 10,000 promotional tokens.'
}];
export var loginAndSettingsData = [{
  id: 1,
  title: 'I have not received a verification link to my email after I have registered.What should I do?',
  content: 'Please check your Spam filter and settings to allow receiving emails from oneecosystem.eu domain. You can always manually request a new verification email by going to the Login window <a href=\'https://oneecosystem.eu/en/#login\'target="_blank"> https://oneecosystem.eu/en/#login</a>   and select "Send an email verification" tab. Enter your Username and press Send.'
}, {
  id: 2,
  title: 'In case I forget my log in password, how can I reset it?',
  content: '1. Go to <a href=\'https://oneecosystem.eu/en/#login\'target="_blank"> https://oneecosystem.eu/en/#login</a>  2. Choose "Forgot password" option. 3. Enter your Username and the e-mail address associated with it. 4. You will receive a verification link and detailed instructions on how to reset your password. Keep in mind that the link will remain valid for 1 (one) hour only.   *If you do not receive an email, please check the junk and spam folders of your mailbox. Another quite common reason is that you have misspelled your email address.'
}, {
  id: 3,
  title: 'How can I reset/generate a transaction password for my account?',
  content: 'Log in to your account and select My Profile -> Security and click on Reset transaction Password. A verification link will be sent to your e-mail address so make sure you acknowledge the link within 1 hour. *Please check the junk and spam folders and email settings, in order to make sure you receive emails from domain oneecosystem.eu.'
}, {
  id: 4,
  title: 'I have tried changing my login/transaction password, but it did not work. What should I do?',
  content: 'Please make sure that your password consists of the following: 8 characters, include at least one capital letter, one number and one letter lower case. If you experience problems changing your password, try the following: delete the browsing history and all temporary files, including cache and cookies from the Internet browser you currently use. If you are not sure how to do so, you can check out one of the many videos on YouTube. In addition, please make sure you use the latest version of Google Chrome or Firefox Internet browsers.'
}, {
  id: 5,
  title: 'Why was my account blocked after I tried to log in 3 consecutive times?',
  content: 'If you have made 3 (three) unsuccessful attempts to log in to your OneEcosystem account, using incorrect password, your account will remain blocked for 3 hours. If you try to log in to your account within the account block period of 3 hours of your last login attempt, your block period will be extended with 3 hours more. We recommend you to reset your login password after the 3-hours account block period is over.'
}, {
  id: 6,
  title: 'How can I change the email address I have registered for my account?',
  content: "In order to change your registered email address, please follow the steps below: 1. Go to your 'My Profile - Profile' 2. Choose 'Edit' button in the Main information section 3. Enter your Transaction password -> Submit4. Fill in your new email address in the “Current E-mail Address” field and press Submit 5. An email will be sent to the currently registered email address. It will contain a verification link which will remain valid for 1 (one) hour only.   Only if you follow the verification link and confirm the e-mail change request, the operation will be completed. Once you do so, you will be re- directed to the backoffice of your account, where you will see a confirmation for the e-mail change. If you experience problems changing your email, please try the following: First, clear the cache and cookies from your browser. If you are not sure how, you can check out one of the many videos on YouTube. Second, use an updated version of Google Chrome or Firefox."
}, {
  id: 7,
  title: 'Can I change my username?',
  content: 'It is not possible to change the username you chose upon registration. However, you can always create another account with the requested username, provided it is still available.'
}];
export var bonusOpportunitiesData = [{
  id: 1,
  title: 'What are the bonus opportunities?',
  content: 'At oneecosystem NETWORK, we understand that our Independent Marketing Associates (IMAs) are an important part of our business. We have created a simple way to earn commissions, team building bonuses and trading rewards for assisting us in sharing our learning and earning platform. Bonuses provided in the OneEcosystem system are : Start up bonus, Direct sales bonus, Network bonus, Matching bonus.'
}, {
  id: 2,
  title: 'How do I qualify for bonuses?',
  content: 'Detailed information on how to qualify for each type of bonus you can find when you select Information center-→Resources-→IMA Tools'
}, {
  id: 3,
  title: 'When are bonuses generated?',
  content: 'Bonuses are generated every Monday at 16:00 GMT Bonus round cut off time is Sunday, 24:00 GMT. Bonuses are allocated to member cash and trading account one week after their generation, on Monday 16:00 GMT. They are distributed as follows: 60% in your Cash account and 40% in your Trading account.'
}, {
  id: 4,
  title: 'Why I have received a bigger amount of bonus in my Trading account than in my Cash account?',
  content: 'Please check your Trading account in case you have received a Start up bonus. The Start up bonus is the only type of bonus which is distributed 100% in the Trading account.'
}];
export var tokensAndSplitsData = [{
  id: 1,
  title: 'What are promotional Tokens?',
  content: 'These are the Tokens provided with the purchase of each package. The amount of Tokens you will receive depends on the value of your package.'
}, {
  id: 2,
  title: 'Can I sell or transfer my tokens?',
  content: 'The tokens are provided with educational packages and are used in order to mine coins with them. They can not be transferred or sold.'
}, {
  id: 3,
  title: 'What is a split and how do I receive it?',
  content: 'The split is a natural development of the price of the promotional tokens, increasing in value due to more packages and bonus being paid out in the system. The split occurs once the Split Barometer(displayed on your Dashboard) reaches 100%. After this tokens in the Tokens account double.'
}, {
  id: 4,
  title: 'Why have I not received the split on my Tokens?',
  content: 'As per the official split rules, only tokens available (not submitted for mining) in the Tokens account will split. This means that in case you have submitted your tokens for mining before the split, you will receive a split of 0. There is however an exception for packages which provide an automining option. These are all packages from Tycoon + and above. They provide you with the opportunity to receive the split even when the tokens have been submitted for mining.'
}, {
  id: 5,
  title: 'What is automining and how can I submit my tokens on automining?',
  content: 'The automining option provides you with the opportunity to submit your tokens for mining on a lower difficulty level than the currently displayed one. In case you have a Tycoon+ package or above, do not forget to check the automining box when you submit your tokens for mining.'
}];
export var sponsorshipData = [{
  id: 1,
  title: 'Can I change my direct sponsor?',
  content: 'A sponsorship change is not possible. In case the account that needs such a change is still with Rookie status and there is no package activated on it, it is allowed to create another, new account with the desired sponsor.'
}, {
  id: 2,
  title: 'Can I be a sponsor of more than one account?',
  content: 'The OneEcosystem Network system provides you with the opportunity to sponsor as many accounts as you would like and earn BV points from all sponsored downlines.'
}, {
  id: 3,
  title: 'I have sponsored a member but I cannot see that downline in my tree',
  content: 'The sponsored member has to first confirm the registration of the account via email.'
}, {
  id: 4,
  title: 'Where can I find my sponsorship link?',
  content: 'Log in to your account and select My Profile→ Profile. On the right side of the page you will see your ‘’Invite link’’. Use this link when you would like to directly sponsor a new member.'
}, {
  id: 5,
  title: 'Can I change/delete the position of members in my Network tree?',
  content: 'Deleting and moving members within the Network tree is not allowed by the policy of the Company.'
}, {
  id: 6,
  title: 'Where can I see all users that I have directly sponsored?',
  content: 'Log in to your account and select from the menu bar Recognition→ OneEcosystem Bonus and on the right side of the page you will be able to view the table of all directly sponsored users by you.'
}, {
  id: 7,
  title: 'How can I contact my sponsor?',
  content: 'Log in to your account and select My Profile . On the right side of the page you will be able to see the ‘’Contact sponsor’’ option.'
}];
export var educationalPackagesAndPaymentsData = [{
  id: 1,
  title: 'Where can I see all available packages?',
  content: 'You can see all provided packages in the Shop section of your account or in the Home page of our official website : <a href=\'oneecosystem.eu\' target="_blank"> oneecosystem.eu</a>'
}, {
  id: 2,
  title: 'What is an upgrade of a package?',
  content: 'If you decide to activate more than one package on your account, this is called an upgrade, as each activated package needs to be with a higher value of the previously activated. If you would like to upgrade your account, you have to pay the whole amount of your new package, without an activation fee.'
}, {
  id: 3,
  title: 'What amount do I have to pay for the activation of an educational package?',
  content: 'When you purchase your first educational package, there is an activation fee of 30EUR, added to the price of the chosen package. This activation fee is paid only with the initial package on the account you would like to activate.'
}, {
  id: 4,
  title: 'What payment methods can I use when I purchase a package?',
  content: 'An educational package can be purchased with one of the following payment methods: *OnePay *Cash Account *Bank wire *Perfect Money *BitCoin *China UnionPay cards'
}, {
  id: 5,
  title: 'I have purchased a Gift code from the Shop section, but it is not working?',
  content: 'The most common reasons are: 1. Misspelled gift code; make also sure you do not leave any spaces in front of or at the end when you enter it. 2. Already used gift code; If you encounter any fraud write to compliance@oneecosystem.eu via a ticket within 24 hours .Timely information is a crucial. 3.The gift code is still not paid. *Please take the necessary measures to ensure that the payments are made directly to IMA-s who are properly identified as such. We urgently warn you and kindly ask you not to trust to unreliable sources and to take into account that the restrictions of giftcode sell are there for a reason. We remind you that resell at a discount price is not allowed, resell is permissible only to downlines, we do not allow facebook or other sell by medias as well.'
}, {
  id: 6,
  title: 'I have purchased a Gift code from the Shop in my backoffice, but my package is not activated. What should I do?',
  content: 'In order to activate your package you have to redeem the gift code you have bought. Log in to your OneEcosystem account and choose Shop. Go to the “Redeem Gift Code” section on the right, enter the gift code number and the Captcha text and select the “Redeem” button.'
}, {
  id: 7,
  title: 'I have already made a payment for my package, but it has not been activated yet. How long do I have to wait?',
  content: 'In order to activate your package, we need a payment confirmation. We receive payment confirmation within 3 to 5 business days after the payment has been submitted. We activate accounts immediately after we verify the payment. *Send proof of payment for your package to accounting@oneecosystem.eu . Do not forget to include the username you would like to activate.'
}, {
  id: 8,
  title: 'Can I receive an invoice for my package?',
  content: 'An invoice can be issued upon request within five days as of the date of the receipt of payment, otherwise we can provide you with an informal receipt. Make sure you Submit a ticket to the Accounting team- <a href=\'accounting@oneecosystem.eu\' target="_blank"> accounting@oneecosystem.eu</a>  Please add the following information:  Physical Entity: 1.Name/2.Package name/3.Address/4.Email address. Judicial Entity: 1.Name of Company/2.Address of Company/3.Email address/4.Package name/5.VAT registration number'
}];
export var miningCoinsAndCoinSafeData = [{
  id: 1,
  title: 'How does the blockchain work ?',
  content: "The blockchain is a secure tool that creates cost-efficient business networks. OneCoin’s blockchain's ledger can track and trace anything of value and consists of each transaction ever performed in OneCoin. It runs every minute, yields 50 000 onecoins per block and stores users' KYC information directly in the blockchain. Because of its centralized system, the OneCoin blockchain does not resemble a public ledger like the blockchains of decentralized cryptocurrencies. The new OneCoin blockchain is unique in its capacity."
}, {
  id: 2,
  title: 'Why I can not see the OneCoin algorithm?',
  content: 'If we would publish the Algorithm of OneCoin TODAY – everyone also outside the Network will be able to mine the coins. This would be unfair for the network and people who paid for packages. Everyone outside the BV system could join the mining. The algorithm will be opened to the public once all coins are mined.'
}, {
  id: 3,
  title: 'How to submit for mining the promotional tokens provided with my package?',
  content: 'In order to submit your promotional tokens for mining, you need to log in to your OneEcosystem account, go to CryptoCurrency -> Mining section. On the right side of the page you will see the amount promotional tokens currently available for mining. You can submit the desired amount of promotional tokens for mining by filling in the empty field and select "Submit".'
}, {
  id: 4,
  title: 'When the mining difficulty increases?',
  content: 'The mining difficulty is an automated process dependent on the number of tokens submitted for mining. When more tokens are submitted in the mining pools, the difficulty level will increase. As a result, the price of the Coin also increases. Please check the difficulty barometer, displayed on your Dashboard.'
}, {
  id: 5,
  title: 'Where can I see my mined coins?',
  content: 'You are able to check the number of coins you have mined in your onecoin account.Please select My accounts--> Onecoin account. '
}, {
  id: 6,
  title: 'How can I use my mined coins?',
  content: 'Once you have mined your coins you can perform the following activities:   1.Convert them to OFC (Options for Future Certificates): This action aims to calculate projected future participation in the public listing of OneCoin. To clarify further, OFCs have no monetary value. OFCs do not grant any ownership, voting or similar rights. For a full explanation of the OFC definition please see the relevant section of the Terms of Use. ---> Users, which have converted their OneCoins into OFCs, benefit from all the services and additional products of the One Ecosystem - such as e-commerce, trading or exchange.   2.Purchase products from DealShaker platform: All members of the OneEcosystem Network can use their OneCoins in the newly launched merchant platform (dealshaker.com). OneCoin holders can use their coins to purchase goods and services there.You are also welcome to join as a merchant - for free - and to offer your services and products to millions of customers worldwide.   3.Transfer coins : Once you have mined your coins, you will be able to transfer them on a daily basis to your downlines and uplines.'
}, {
  id: 7,
  title: 'Where can I see the current price of ONE coin?',
  content: 'When you log in to your account, at the bottom left side of your Dashboard you will be able to check the current price of the coin.'
}, {
  id: 8,
  title: 'What is CoinSafe?',
  content: 'CoinSafe is one of our products which allows you to deposit all of your currently mined coins and safe them for a certain period of time. As a result you will receive an interest over the amount of deposited coins. Further details regarding the interests you can find in the following video: <a href=\'https://youtube.com/watch?v=ZwIlBy3IJJE\' target="_blank"> https://youtube.com/watch?v=ZwIlBy3IJJE</a> '
}, {
  id: 9,
  title: 'I have deposited my coins to CoinSafe, however I would like to cancel the process?',
  content: 'In case you decided to deposit your OneCoin balance (all coins you had) to your CoinSafe account, the operation cannot be cancelled. You have to wait until the end of the interest period you have chosen. However, you have the option to choose whether any new OneCoins, you may acquire during the interest period, be also credited to your CoinSafe account or not by enabling / disabling the Autosaving feature. You can also use the deposited coins to purchase items on the DealShaker platform. When you do so, the interest period on this coins will be canceled.'
}];
export var accountVerificationAndComplianceData = [{
  id: 1,
  title: 'How OneEcosystem provides security to members?',
  content: 'In terms of security, OneEcosystem is extremely secure. The reasons for this are as follows : 1.We have a centralized Database with our client’s e-wallets and accounts. We back up this database several times per hour. All transactions can be tracked and even a crash will not make coins disappear, as we back up very frequently.   2. Coins do not leave the e-wallets currently, they are transferred within the system. If you claim that your account has been hacked – we can track immediately the transaction and restore the balance.   3.When someone makes a withdraw before the amount is transferred outside of the system there are compliance and security checks to be made.   4.We use the https certificate as MANDATORY. If someone wants to load the website and has issues - he has to click: trust this website.'
}, {
  id: 2,
  title: 'I think my account has been hacked, what should I do?',
  content: 'In case that you have an access to your registered email, you are able simply to use the "Forgot password" function in Login window to reset your password. Input your Username and Email, and click on the Forgot Password button. An email with your new password will be sent to you. For further investigation or to gain access to your account( if you have not got access to your email) we advise you to contact the Compliance department <a href=\'compliance@oneecosystem.eu\' target="_blank"> compliance@oneecosystem.eu</a> via a ticket within 24 hours.Timely information is  crucial.'
}, {
  id: 3,
  title: 'How to maintain the safety on my account?',
  content: 'The safety of your account is our priority and therefore every 8th week you will have to change your login and transactions passwords. Remember to always choose a password, different than the one you have already had and do not use passwords that have resemblance to your account’s username.'
}, {
  id: 4,
  title: 'What are KYC documents?',
  content: 'Because of our centralized model, we take care of all technical aspects, ensuring that your mined coins are safe. OneEcosystem has implemented KYC (know-your-customer) procedures, thus preventing any possible misconduct by its clients. The KYC documents consist of : Proof of ID and Proof of Address. All the detailed requirements regarding the KYC documents you can find in your backoffice. Please select My Profile→KYC.'
}, {
  id: 5,
  title: 'Why do I need to upload my KYC documents?',
  content: 'The KYC documents of each user need to be uploaded and verified due to compliance reasons. In order to assure the security within the network you need to upload the necessary documents after registrating in the system. Once the documents have been approved, your account verification is guaranteed and you will be able to withdraw funds from your cash account.'
}, {
  id: 6,
  title: 'I have submitted my KYC documents for Approval but they have not been verified yet.How long do I have to wait?',
  content: 'Due to the many submitted document applications and the close monitoring process the KYC review may take up to 60 business days.'
}, {
  id: 7,
  title: 'My KYC status is DECLINED with the following reason: outdated KYC documents.',
  content: 'We have noticed you have been inactive in the last 12 months, for which reason your uploaded KYC documents have been declined and your available OneCoin account balance nullified. Please make sure to upload KYC documents which  comply with the official requirements. For further information, please check My Profile-->KYC.Only clearly scanned high-resolution images having all details visible will be accepted. KYC documents should not contain visible pixels or dot patterns.Please be advised that your OneCoins will be credited to your account within 10 working days after your KYC has been approved.'
}, {
  id: 8,
  title: 'Where can I find the Whistleblower report?',
  content: 'In order to find and fill the Whistleblower report, please log in to your account and select from the menu bar Support-->Whistleblower report.  Fill in all the required information and select "Submit Report".'
}];
export var generalAccountInformationData = [{
  id: 1,
  title: 'Can I register more than one account?',
  content: 'The OneEcosystem Network provides you with the opportunity to register as many accounts as you would like.'
}, {
  id: 2,
  title: 'Can a Rookie account be frozen?',
  content: 'Due to compliance reasons and as a precautionary measure the Company accepted the policy that any Rookie account that has not been active within 14 days after login/acquisition has automatically become dormant/frozen. Re-activation of those accounts will be performed upon a request from the owner and submission or secondary submission of KYC documentation for identity verification. In case of non-provision of the KYC documents the account will be terminated.'
}, {
  id: 3,
  title: 'How can I change the country I have registered?',
  content: 'The change of the country is possible only once in every 3 months. When the period from your last modification passes, you would be able to do the needed change when you log in to your account and select My Profile→ Profile.'
}, {
  id: 4,
  title: 'How can I obtain an OneAcademy certificate?',
  content: 'You have to successfully complete all chapters in your OneAcademy level (provided with your package) in order to obtain a certificate. After you successfully pass an OneAcademy level, a result page is generated that contains your certificate. You can always download your generated certificate when you log in to OneAcademy - <a href=\'oneacademy.eu\' target="_blank"> oneacademy.eu</a> '
}];