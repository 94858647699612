function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _orderBy from "lodash/orderBy";
import _omit from "lodash/omit";
import _isArray from "lodash/isArray";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import qs from 'query-string';
import { add } from 'exact-math';
import { accountRank, accountRankId } from '../../enums/account';
import { startOfTheDay, endOfTheDay } from '../../utils/moment';
export var getBonusRunsParams = function getBonusRunsParams(options) {
  var _options$pageIndex = options.pageIndex,
    pageIndex = _options$pageIndex === void 0 ? 0 : _options$pageIndex,
    _options$pageSize = options.pageSize,
    pageSize = _options$pageSize === void 0 ? 5 : _options$pageSize,
    startDate = options.startDate,
    endDate = options.endDate,
    accountId = options.accountId,
    sort = options.sort,
    status = options.status,
    bonusType = options.bonusType,
    isPreliminary = options.isPreliminary;
  var sorting = sort !== null && sort !== void 0 ? sort : {
    sortOrder: 'desc'
  };
  var dateRange = {};
  if (startDate) dateRange.TargetDateFrom = startOfTheDay(startDate).format();
  if (endDate) dateRange.TargetDateTo = endOfTheDay(endDate).format();
  return qs.stringify(_objectSpread(_objectSpread(_objectSpread({
    pageIndex: pageIndex,
    pageSize: pageSize
  }, dateRange), sorting), {}, {
    accountId: accountId,
    status: status,
    bonusType: _isArray(bonusType) ? bonusType : [bonusType],
    isPreliminary: isPreliminary,
    loadPackageInfoForRounds: true
  }), {
    arrayFormat: 'index'
  });
};
export var getBonusHistoryParams = function getBonusHistoryParams(options) {
  var _options$pageIndex2 = options.pageIndex,
    pageIndex = _options$pageIndex2 === void 0 ? 0 : _options$pageIndex2,
    _options$pageSize2 = options.pageSize,
    pageSize = _options$pageSize2 === void 0 ? 10 : _options$pageSize2,
    startDate = options.startDate,
    endDate = options.endDate,
    _options$sortField = options.sortField,
    sortField = _options$sortField === void 0 ? 'date' : _options$sortField,
    _options$sortValue = options.sortValue,
    sortValue = _options$sortValue === void 0 ? 'desc' : _options$sortValue,
    type = options.type;
  var dateRange = {};
  if (startDate) dateRange.From = startOfTheDay(startDate).format();
  if (endDate) dateRange.Until = endOfTheDay(endDate).format();
  return qs.stringify(_objectSpread(_objectSpread({
    pageIndex: pageIndex,
    pageSize: pageSize
  }, dateRange), {}, {
    sortValue: sortValue,
    sortField: sortField,
    type: type
  }));
};
export var getLeadershipHistoryModel = function getLeadershipHistoryModel(data) {
  var getSum = function getSum(leadershipAccounts) {
    var _leadershipAccounts$r;
    return leadershipAccounts === null || leadershipAccounts === void 0 ? void 0 : leadershipAccounts.reduce(function (acc, item) {
      var key = accountRankId[item.rankId];
      acc[key] += item.networkAccountsCount;
      return acc;
    }, (_leadershipAccounts$r = {}, _defineProperty(_leadershipAccounts$r, accountRank.Rookie, 0), _defineProperty(_leadershipAccounts$r, accountRank.Sapphire, 0), _defineProperty(_leadershipAccounts$r, accountRank.Ruby, 0), _defineProperty(_leadershipAccounts$r, accountRank.Emerald, 0), _defineProperty(_leadershipAccounts$r, accountRank.Diamond, 0), _defineProperty(_leadershipAccounts$r, accountRank.BlueDiamond, 0), _defineProperty(_leadershipAccounts$r, accountRank.BlackDiamond, 0), _defineProperty(_leadershipAccounts$r, accountRank.CrownDiamond, 0), _leadershipAccounts$r));
  };
  return _orderBy(data.map(function (period) {
    return _objectSpread(_objectSpread(_objectSpread({}, _omit(period, 'networkLeadershipRankDetails')), getSum(period.networkLeadershipRankDetails)), {}, {
      bvTotalForPeriod: period.bvLeftLegForPeriod + period.bvRightLegForPeriod
    });
  }), ['rankRunTargetDateTo'], ['desc']);
};
export var getPreliminaryLeadershipRunResponse = function getPreliminaryLeadershipRunResponse(response) {
  var _response$bvLeftLegFo, _response$bvRightLegF;
  var leftLeg = (_response$bvLeftLegFo = response === null || response === void 0 ? void 0 : response.bvLeftLegForPeriod) !== null && _response$bvLeftLegFo !== void 0 ? _response$bvLeftLegFo : 0;
  var rightLeg = (_response$bvRightLegF = response === null || response === void 0 ? void 0 : response.bvRightLegForPeriod) !== null && _response$bvRightLegF !== void 0 ? _response$bvRightLegF : 0;
  return _objectSpread(_objectSpread({}, response), {}, {
    totalBvPoints: add(leftLeg, rightLeg),
    weakerLegBvPoints: Math.min(leftLeg, rightLeg),
    weakerLeg: leftLeg < rightLeg ? 'Left' : 'Right'
  });
};
export var startUpDirectBonusModel = function startUpDirectBonusModel(response) {
  var _response$businessVol;
  var businessVolumeData = response === null || response === void 0 ? void 0 : (_response$businessVol = response.businessVolumes) === null || _response$businessVol === void 0 ? void 0 : _response$businessVol.map(function (el) {
    var _el$bonuses;
    if (((_el$bonuses = el.bonuses) === null || _el$bonuses === void 0 ? void 0 : _el$bonuses.length) > 0) {
      var _el$bonuses$ = el.bonuses[0],
        totalBonus = _el$bonuses$.totalBonus,
        bonusCap = _el$bonuses$.bonusCap,
        paidBonus = _el$bonuses$.paidBonus;
      return _objectSpread(_objectSpread({}, el), {}, {
        totalBonus: totalBonus,
        bonusCap: bonusCap,
        paidBonus: paidBonus
      });
    }
    return _objectSpread({}, el);
  });
  return _objectSpread(_objectSpread({}, response), {}, {
    businessVolumes: businessVolumeData,
    total: response === null || response === void 0 ? void 0 : response.total
  });
};
export var getLeadershipRoundsParams = function getLeadershipRoundsParams(options) {
  var filterField = [];
  var filterValue = [];
  if (options !== null && options !== void 0 && options.status) {
    filterField.push('status');
    filterValue.push(options.status);
  }
  if (options !== null && options !== void 0 && options.dateFrom) {
    filterField.push('dateFrom');
    filterValue.push(options.dateFrom);
  }
  return qs.stringify(_objectSpread(_objectSpread(_objectSpread({}, _omit(options, ['status', 'dateFrom', 'sort'])), filterField.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {}, {
    sortField: 'runId',
    sortOrder: 'desc'
  }), {
    arrayFormat: 'index'
  });
};