import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../dashboard/DashboardPage';
import Profile from '../profile/ProfilePage';
import Bonus from '../bonuses/BonusPage';
import Recognition from '../recognition/RecognitionPage';
import Wallet from '../wallet/WalletPage';
import Network from '../network/NetworkPage';
import Shop from '../shop/ShopPage';
import InformationCenter from '../help/informationCenter/InformationCenterPage';
import TermsAndConditions from '../help/termsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../help/termsAndConditions/PrivacyPolicy';
import { checkStatus } from '../../utils/checkStatus';
import { jsx as ___EmotionJSX } from "@emotion/react";
var HomePage = function HomePage(_ref) {
  var user = _ref.user,
    hasAccounts = _ref.hasAccounts;
  var chineseProfile = user.country.toLowerCase() === 'china';
  if (!(user !== null && user !== void 0 && user.userId)) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
      className: "vk-container"
    }, ___EmotionJSX("h2", null, "There was a problem loading user profile. Please, sign out and try again")));
  }
  if (!checkStatus(user === null || user === void 0 ? void 0 : user.status)) return ___EmotionJSX("h2", null, "Your current profile is ", user === null || user === void 0 ? void 0 : user.status, " and you do not have access to the portal.");
  if (!hasAccounts) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
      className: "vk-container"
    }, ___EmotionJSX("h2", null, "This profile has no accounts. Please create an account.")));
  }
  var myNetworkPath = chineseProfile ? 'my-community' : 'my-network';
  return ___EmotionJSX(Switch, null, ___EmotionJSX(Redirect, {
    exact: true,
    from: "/",
    to: "/dashboard"
  }), chineseProfile ? ___EmotionJSX(Redirect, {
    from: "/my-network",
    to: "/my-community"
  }) : ___EmotionJSX(Redirect, {
    from: "/my-community",
    to: "/my-network"
  }), ___EmotionJSX(Route, {
    path: "/profile",
    component: Profile
  }), ___EmotionJSX(Route, {
    path: "/bonus",
    component: Bonus
  }), ___EmotionJSX(Route, {
    path: "/recognition",
    component: Recognition
  }), ___EmotionJSX(Route, {
    path: "/wallet",
    component: Wallet
  }), ___EmotionJSX(Route, {
    path: "/".concat(myNetworkPath),
    component: Network
  }), ___EmotionJSX(Route, {
    path: "/dashboard",
    component: Dashboard
  }), ___EmotionJSX(Route, {
    path: "/shop",
    component: Shop
  }), ___EmotionJSX(Route, {
    path: "/information-center",
    component: InformationCenter
  }), ___EmotionJSX(Route, {
    path: "/terms",
    component: TermsAndConditions
  }), ___EmotionJSX(Route, {
    path: "/privacy-policy",
    component: PrivacyPolicy
  }), ___EmotionJSX(Redirect, {
    to: "/error"
  }));
};
export default HomePage;